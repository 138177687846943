// extracted by mini-css-extract-plugin
export var container = "NavMenu-module--container--dMAMX";
export var toggleMenu = "NavMenu-module--toggleMenu--bA5k7";
export var active = "NavMenu-module--active--AZ6HL";
export var menuContainer = "NavMenu-module--menuContainer--P3PAT";
export var open = "NavMenu-module--open--eOYfS";
export var nav = "NavMenu-module--nav--9WCK7";
export var tag = "NavMenu-module--tag--RVgC4";
export var textClipEnter = "NavMenu-module--textClipEnter--iHFhp";
export var explore = "NavMenu-module--explore--8GJq3";
export var exploreTextEnter = "NavMenu-module--exploreTextEnter--Pb9MJ";
export var link = "NavMenu-module--link--ONY+8";